<template>
  <v-row>
    <!-- local unit / project -->
    <v-col :cols="colNumber" class="pa-1 pl-3">
      <v-menu v-model="openMenu" :value="openMenu" allow-overflow offset-y max-height="400px" :disabled="disabled" :key="forceUpdate" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            @click.stop="openMenu = true"
            dense
            solo
            hide-details
            autocomplete="off"
            :disabled="disabled"
            v-model="search"
            return-object
            clearable
            @click:clear="clearSearchText()"
            @blur="textBlur()"
            @input="value => inputHandler(value)">
          </v-text-field>
        </template>

        <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
          <div v-if="pileType && pileType.type === 'SPECIAL'" class="d-flex align-center justify-space-between px-2 pt-2">
            <div class="d-flex align-center mr-2">
              <!-- localUnit menu -->
              <v-btn @click.prevent="$emit('set-active-menu-list', 'localUnits'), (changedProjectList = true)" x-small class="mr-2" :class="[activeMenuList === 'localUnits' ? 'white--text' : '']" :outlined="activeMenuList !== 'localUnits'" color="main">
                {{ translations.localUnit }}
              </v-btn>

              <!-- project menu -->
              <v-btn @click.prevent="$emit('set-active-menu-list', 'projects'), (changedProjectList = true)" x-small :class="[activeMenuList === 'projects' ? 'white--text' : '']" :outlined="activeMenuList !== 'projects'" color="main">
                {{ translations.projects }}
              </v-btn>
            </div>
            <v-btn x-small icon @click.stop="closeMenuHandler()">
              <v-icon x-small>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </div>

          <v-list dense>
            <!-- local unit list -->
            <template v-if="activeMenuList === 'localUnits'">
              <v-subheader v-if="pileType && pileType.type === 'SPECIAL'">{{ translations.localUnit }}</v-subheader>
              <v-list-item v-for="localUnit in localUnitOptions.list" :key="localUnit.name" @click="changeLocalUnit(localUnit)">
                <v-list-item-content>
                  <v-list-item-title>{{ localUnit.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ localUnit.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!localUnitOptions.loading && localUnitOptions.next">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
              <v-list-item v-if="!localUnitOptions.loading && !localUnitOptions.next && localUnitOptions.list.length === 0">
                <v-list-item-content>
                  <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <!-- project list -->
            <template v-else-if="activeMenuList === 'projects'">
              <v-subheader>{{ translations.projects }}</v-subheader>
              <v-list-item v-for="project in projectOptions.list" @click="changeProject(project)" :key="project.keyCode">
                <v-list-item-content>
                  <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!projectOptions.loading && projectOptions.next">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
              <v-list-item v-if="!projectOptions.loading && !projectOptions.next && projectOptions.list.length === 0">
                <v-list-item-content>
                  <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-menu>

      <!-- localUnit/project description -->
      <div class="d-flex flex-wrap" style="flex-direction: column; flex: 50%;">
        <p class="pa-0 ma-0 py-1 grey--text" style="font-size: 12px">
          {{ pileLocalUnitProject.project ? pileLocalUnitProject.project.label : pileLocalUnitProject.localUnit ? pileLocalUnitProject.localUnit.description : '' }}
        </p>
      </div>
    </v-col>

    <!-- Part -->
    <v-col v-if="hasParts" cols="3" class="pa-1">
      <v-select v-model="pileLocalUnitProject.part" return-object :items="projectParts()" item-text="keyCode" :no-data-text="translations.noPartsAvailable" append-icon="" item-value="id" :disabled="disabled" dense solo hide-details clearable @click:clear="pileLocalUnitProject.part = null">
        <template v-slot:item="data">
          <small>{{ `${data.item.keyCode} - ${data.item.description}` }}</small>
        </template>
      </v-select>

      <!-- part description -->
      <div v-if="pileLocalUnitProject.part" class="d-flex flex-wrap" style="flex-direction: column; flex: 50%;">
        <p class="pa-0 ma-0 py-1 grey--text" style="font-size: 12px">
          {{ pileLocalUnitProject.part.description }}
        </p>
      </div>
    </v-col>

    <!-- certificate upload -->
    <v-col cols="1" style="justify-content:center;display:flex;" class="pa-1" v-if="['STANDARD', 'SPECIAL'].includes(pileType.type) || (isUpdate && pileTypeIsLimited && pileClosed)">
      <v-btn :color="pdfIconColor" icon @click="handleFileUpload">
        <v-icon>
          {{ icons.mdiFilePdfOutline }}
        </v-icon>
      </v-btn>

      <input ref="uploader" class="d-none" type="file" accept="application/pdf" @change="onFileChanged" />
    </v-col>

    <!-- add/remove pileLocalUnitProject button -->
    <v-col cols="1" v-if="pileType && pileType.type === 'SPECIAL'" class="pa-1">
      <div class="d-flex flex-column align-center">
        <v-btn :disabled="isLast" icon @click="$emit('remove')">
          <v-icon color="main">
            {{ icons.mdiCloseCircle }}
          </v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { translations } from '@/utils/common'
import { mdiTrashCanOutline, mdiPlus, mdiClose, mdiFilePdfOutline, mdiCheckCircle, mdiPlusCircle, mdiCloseCircle } from '@mdi/js'
import { cloneDeep } from 'lodash'

export default {
  name: 'PileLocalUnitProject',

  props: {
    pileLocalUnitProject: {
      type: Object,
      default: () => {}
    },
    activeMenuList: {
      type: String,
      default: 'localUnits'
    },
    projectOptions: {
      type: Object,
      default: () => {}
    },
    localUnitOptions: {
      type: Object,
      default: () => {}
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    pileType: {
      type: Object,
      default: () => {}
    },
    pileClosed: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    source: {},
    openMenu: false,
    forceUpdate: 0,
    forceUpdateLabel: 0,
    search: null,
    changedProjectList: false,
    isSelecting: false
  }),

  created() {
    this.search = this.getSelectedValue()
    this.source = cloneDeep(this.pileLocalUnitProject)
  },

  methods: {
    textBlur() {
      setTimeout(() => {
        if (this.changedProjectList) {
          this.changedProjectList = false
          return
        } else {
          this.search = this.getSelectedValue()
          this.openMenu = false
          if (this.pileType.type === 'SPECIAL') {
            this.$emit('refetch', 'project')
          }
          this.$emit('refetch', 'localUnit')
        }
      }, 400)
    },

    getSelectedValue() {
      if (this.pileLocalUnitProject?.localUnit?.name) {
        return this.pileLocalUnitProject?.localUnit?.name
      } else if (this.pileLocalUnitProject?.project?.keyCode) {
        return this.pileLocalUnitProject.project.keyCode
      } else return ''
    },

    inputHandler(value) {
      this.search = value
      this.localUnitOptions.params.localUnitName = value
      if (this.pileType.type === 'SPECIAL') {
        this.projectOptions.params.filter = value
        this.$emit('refetch', 'project')
      }
      this.$emit('refetch', 'localUnit')
    },

    closeMenuHandler() {
      this.search = this.getSelectedValue()
      this.localUnitOptions.params.localUnitName = this.search
      this.openMenu = false
      if (this.pileType.type === 'SPECIAL') {
        this.projectOptions.params.filter = this.search
        this.$emit('refetch', 'project')
      }
      this.$emit('refetch', 'localUnit')
    },

    changeProject(value) {
      this.pileLocalUnitProject.project = value?.project ? value.project : value
      this.pileLocalUnitProject.part = null
      this.pileLocalUnitProject.localUnit = null
      this.search = value.keyCode
      this.openMenu = false
    },

    changeLocalUnit(value) {
      this.pileLocalUnitProject.localUnit = value?.localUnit ? value.localUnit : value
      this.pileLocalUnitProject.project = null
      this.pileLocalUnitProject.part = null
      this.search = value.name
      this.openMenu = false
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.activeMenuList === 'projects') {
          return this.$emit('fetch-more', 'project')
        } else {
          return this.$emit('fetch-more', 'localUnit')
        }
      }
    },

    projectParts() {
      return this.pileLocalUnitProject?.project?.parts
    },

    handleFileUpload() {
      this.isSelecting = true

      // after obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      // trigger click on the FileInput
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.$set(this.pileLocalUnitProject, 'certificateData', e.target.files[0])
    },

    clearSearchText() {
      this.search = null
      this.pileLocalUnitProject.project = null
      this.pileLocalUnitProject.localUnit = null
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiClose,
      mdiPlus,
      mdiTrashCanOutline,
      mdiFilePdfOutline,
      mdiCheckCircle,
      mdiPlusCircle,
      mdiCloseCircle
    }),

    pileTypeIsLimited() {
      if (!this.pileType?.type) return false

      return this.pileType.limited
    },

    colNumber() {
      if (this.pileLocalUnitProject?.project?.parts?.length) {
        return '7'
      } else if (['STANDARD', 'SPECIAL'].includes(this.pileType.type) || (this.pileTypeIsLimited && this.isUpdate && this.pileClosed)) {
        return '10'
      } else {
        return '12'
      }
    },

    disabled() {
      return this.pileTypeIsLimited
    },

    hasParts() {
      return this.pileLocalUnitProject?.project?.parts?.length
    },

    pdfIconColor() {
      return this.pileLocalUnitProject.certificate || this.pileLocalUnitProject.certificateData || this.certificateFile ? 'red' : 'grey'
    },

    localUnitValue() {
      return this.pileLocalUnitProject?.localUnit
    },

    projectValue() {
      return this.pileLocalUnitProject?.project
    }
  },

  watch: {
    localUnitValue() {
      this.search = this.getSelectedValue()
    },

    projectValue() {
      this.search = this.getSelectedValue()
    }
  }
}
</script>
