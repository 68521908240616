<template>
  <v-dialog v-model="isOpen" max-width="600px" @click:outside="$emit('close-certificate-dialog')">
    <v-card class="pa-2">
      <v-card-title>
        <v-row class="ma-2" justify="center">
          <h3 class="main--text">
            {{ showExtendedOptions ? translations.certificates : translations.downloadCertificates }}
          </h3>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="pileLocalUnitProject of localUnitProjectsList" :key="pileLocalUnitProject.id" >
            <v-row class="d-flex align-center justify-center">
              <!-- localUnit / project -->
              <v-col cols="7">
                <v-list-item-content>
                  <v-list-item-title >{{ pileLocalUnitProject.localUnit ? pileLocalUnitProject.localUnit.name : (pileLocalUnitProject.project.keyCode + " - " + pileLocalUnitProject.project.label) }}</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <!-- part -->
              <v-col cols="2">
                <v-list-item-content>
                  <v-list-item-title >{{ pileLocalUnitProject.part ? pileLocalUnitProject.part.keyCode : "" }}</v-list-item-title>
                </v-list-item-content>
              </v-col>

              <!-- certificate actions -->
              <v-col cols="3">
                <v-list-item-action>
                  <v-row class="d-flex align-center justify-center">
                    <!-- document -->
                    <v-col cols="4">
                      <v-checkbox v-if="showExtendedOptions" :disabled="pileLocalUnitProject.certificate" :v-model="pileLocalUnitProject.document" :input-value="pileLocalUnitProject.document" color="main" :ripple="false" hide-details @change="sendCheckboxUpdatedEvent($event, pileLocalUnitProject)"></v-checkbox>
                    </v-col>

                    <!-- upload -->
                    <v-col cols="4">
                      <v-btn icon v-if="showExtendedOptions" @click="sendUploadCertificateEvent(pileLocalUnitProject)">
                        <v-icon>
                          {{ icons.mdiUpload }}
                        </v-icon>
                      </v-btn>
                    </v-col>

                    <!-- download -->
                    <v-col cols="4">
                      <v-btn :disabled="!pileLocalUnitProject.certificate" icon :href="pileLocalUnitProject.certificateBase64" :download="pileLocalUnitProject.certificateName">
                        <v-icon color="red">
                          {{ icons.mdiFilePdfOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <p v-if="!localUnitProjectsList.length" class="ma-0 pa-0 text-center">{{ translations.noItemsFound }}</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { translations } from "@/utils/common";
import { mdiFilePdfOutline, mdiUpload } from "@mdi/js";

export default {
  name: "CertificateDialog",

  props: {
    pileLocalUnitProjects: {
      type: Array,
      required: true,
      dafault: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    extendedOptions: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isOpen: false,
    localUnitProjectsList: [],
    showExtendedOptions: false
  }),

  methods: {
    sendCheckboxUpdatedEvent(value, item) {
      item.pileLocalUnitProjectId = item.id;
      this.$emit('checkbox-updated-event', {value: value, item: item});
    },

    sendUploadCertificateEvent(item) {
      this.$emit('upload-certificate', item)
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiFilePdfOutline,
      mdiUpload
    })
  },

  watch: {
    open() {
      this.isOpen = this.open;
    },

    pileLocalUnitProjects() {
      this.localUnitProjectsList = this.pileLocalUnitProjects;
    },

    extendedOptions() {
      this.showExtendedOptions = this.extendedOptions;
    },
  }
}
</script>
