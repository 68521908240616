<template>
  <div>
    <apexchart type="pie" :width="width" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'PieChart',

  props: {
    width: {
      type: Number,
      default: 215
    },

    pile: {
      type: Object,
      default: () => {
      }
    },

    pileType: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          animations: {
            enabled: false
          }
        },
        colors: [],
        labels: [],
        legend: {
          show: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10
            },
            expandOnClick: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(value, { seriesIndex, w }) {
            return w.config.labels[seriesIndex] ? 'T' + w.config.labels[seriesIndex] : ''
          },
          style: {
            fontWeight: 500
          },
          dropShadow: {
            enabled: true,
            top: 2,
            left: 2,
            color: '#333',
            opacity: 1
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#333']
        },
        states: {
          hover: {
            filter: 'none'
          }
        }
      }
    }
  },

  methods: {
    getPercentageOf(value, total) {
      return parseFloat(((value / total) * 100).toFixed(2))
    },

    getPartialSlicePercentage(value) {
      let { limit } = this.pileType
      return this.getPercentageOf(value, limit)
    },

    buildChart() {
      const { totalTons } = this.pile
      const { tiers, limit } = this.pileType

      let colors = []
      let series = []
      let labels = []

      // empty pile
      if (totalTons === 0) {
        series = [100]
        colors = ['#FFFFFF']

        // full pile
      } else if (totalTons >= limit) {
        tiers.forEach((tier, index) => {
          const { color, tierLimit } = tier
          let percentage

          //if first tier
          if (index === 0) {
            percentage = this.getPercentageOf(tierLimit, limit)
          } else {
            percentage = this.getPercentageOf(tierLimit - tiers[index - 1].tierLimit, limit)
          }

          series.push(percentage)
          colors.push(color)
          labels.push(tierLimit)
        })

        // partially filled
      } else {
        const whiteSlicePercentage = this.getPercentageOf(limit - totalTons, limit)

        colors = ['#FFFFFF']
        series = [whiteSlicePercentage]

        for (let i = 0; i < tiers.length; i++) {
          const { tierLimit, color } = tiers[i]

          // if first tier
          if (i === 0) {
            colors.unshift(color)
            //set label if tierlimit is less or equal than total tons set tierLimit otherwise total
            const label = tierLimit <= totalTons ? tierLimit : totalTons
            labels.push(label)

            const tonsForPercentage = totalTons <= tierLimit ? totalTons : tierLimit
            series.unshift(this.getPartialSlicePercentage(tonsForPercentage))
          } else if (totalTons > tiers[i - 1].tierLimit && totalTons <= tierLimit) {
            colors.splice(-1, 0, color)
            series.splice(-1, 0, this.getPartialSlicePercentage(tierLimit - tiers[i - 1].tierLimit))

            const label = tierLimit <= totalTons ? tierLimit : totalTons
            labels.push(label)
          } else if (totalTons >= tierLimit) {
            colors.splice(-1, 0, color)
            series.splice(-1, 0, this.getPartialSlicePercentage(tierLimit - tiers[i - 1].tierLimit))
            labels.push(tierLimit)
          }
        }
      }
      this.series = series
      this.chartOptions = { ...this.chartOptions, ...{ colors, labels } }
    }
  },

  created() {
    this.buildChart()
  },

  watch: {
    pile() {
      this.buildChart()
    }
  }
}
</script>
